import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import client from "../client.js";
import BlockContent from "@sanity/block-content-to-react";
import imageUrlBuilder from "@sanity/image-url";
import { Paper, Typography } from "@material-ui/core";

const builder = imageUrlBuilder(client);
function urlFor(source) {
  return builder.image(source);
}

const OnePost = () => {
  const [postData, setPostData] = useState(null);
  const { slug } = useParams();

  const clientConfig = client.config();

  useEffect(() => {
    client
      .fetch(
        `*[slug.current == $slug]{
          title,
          slug,
          mainImage{
            asset->{
              _id,
              url
             }
           },
         body,
        "name": author->name,
        "authorImage": author->image
       }`,
        { slug }
      )
      .then((data) => setPostData(data[0]))
      .catch(console.error);
  }, [slug]);

  if (!postData) return <div>Loading...</div>;

  return (
    <Paper className="mainBody single-post">
      <Paper className="clearFix post-header">
        <div class="figure">
          <img
            src={urlFor(postData.authorImage).width(100).url()}
            alt="Testing stuff"
          />
          <Typography>Author: {postData.name}</Typography>
        </div>
        <Typography className="single-post-title" variant="h3">{postData.title}</Typography>
      </Paper>
      <img className="single-post-image" src={urlFor(postData.mainImage).width(200).url()} alt="" />
      <BlockContent
        blocks={postData.body}
        projectId={clientConfig.projectId}
        dataset={clientConfig.dataset}
      />
    </Paper>
  );
};
export default OnePost;
