import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import client from "../client.js";
import { Card, Typography } from "@material-ui/core";

const AllPosts = () => {
  const [allPostsData, setAllPosts] = useState(null);

  useEffect(() => {
    client
      .fetch(
        `*[_type == "post"]{
        title,
        slug,
        mainImage{
          asset->{
          _id,
          url
        }
      }
    }`
      )
      .then((data) => setAllPosts(data))
      .catch(console.error);
  }, []);

  return (
    <Card className="mainBody">
      {allPostsData &&
        allPostsData.map((post, index) => (
          <div key={index} className="item">
            <Link to={"/" + post.slug.current} key={post.slug.current}>
            <span>
              <img className="all-post-link" src={post.mainImage.asset.url} alt="" />
              <span>
                <Typography className="all-post-title" variant="h3">{post.title}</Typography>
              </span>
            </span>
          </Link>
          </div>
        ))}
    </Card>
  );
};
export default AllPosts;
