import PTSansRegular from "../fonts/PTSans-Regular.ttf"
import PTSansItalic from "../fonts/PTSans-Italic.ttf"
import PTSansBold from "../fonts/PTSans-Bold.ttf"

export const ptSansRegular = {
  fontFamily: 'PTSans Regular',
  fontStyle: 'Regular',
  fontDisplay: 'swap',
  fontWeight: 400,
  src: `
    local('PTSans Regular'),
    local('PTSans-Regular'),
    url(${PTSansRegular}) format('ttf')
  `,
  unicodeRange:
    'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};

export const ptSansItalic = {
  fontFamily: 'PTSans Italic',
  fontStyle: 'Italic',
  fontDisplay: 'swap',
  fontWeight: 400,
  src: `
    local('PTSans Italic'),
    local('PTSans-Italic'),
    url(${PTSansItalic}) format('ttf')
  `,
  unicodeRange:
    'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};

export const ptSansBold = {
  fontFamily: 'PTSans Bold',
  fontStyle: 'Bold',
  fontDisplay: 'swap',
  fontWeight: 400,
  src: `
    local('PTSans Bold'),
    local('PTSans-Bold'),
    url(${PTSansBold}) format('ttf')
  `,
  unicodeRange:
    'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};