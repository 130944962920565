import React from "react";
import {Paper, Typography} from "@material-ui/core"

const Header = () => {
  return (
    <Paper className="header">
      <Typography variant="h1">FehrIsFair All Posts</Typography>
      <Typography variant="h2">Hello and welcome to my blog!</Typography>
    </Paper>
  );
};
export default Header
