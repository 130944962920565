import React from "react";
import { BrowserRouter, Route } from "react-router-dom";
import "fontsource-roboto";

import AllPosts from "./Components/AllPosts";
import OnePost from "./Components/OnePost";
import Header from "./Components/Header";
import SiteThemeProvider from "./themes/theme";
import Footer from "./Components/Footer"

import "./site.css";

function App() {
  return (
    <div className="App">
      <SiteThemeProvider>
        <div id="container">
          <Header />
          <BrowserRouter>
            <Route path="/" exact component={AllPosts} />
            <Route path="/:slug" component={OnePost} />
          </BrowserRouter>
          <Footer />
        </div>
      </SiteThemeProvider>
    </div>
  );
}

export default App;
