import { createTheme, ThemeProvider } from "@material-ui/core/styles";

import { ptSansBold, ptSansRegular, ptSansItalic } from "./fonts";

const theme = createTheme({
  typography: {
    fontFamily: "Pt Sans, Arial",
  },
  overrides: {
    MuiCssBaseline: {
      "@global": {
        "@font-face": [ptSansBold, ptSansItalic, ptSansRegular],
      },
    },
  },
});

theme.typography.h1 = {
  fontSize: '2.2rem',
};

theme.typography.h2 = {
  fontSize: '2rem',
};

theme.typography.h3 = {
  fontSize: '1.8rem',
};

theme.typography.h4 = {
  fontSize: '1.6rem',
};

theme.typography.h5 = {
  fontSize: '1.4rem',
};

theme.typography.h6 = {
  fontSize: '1.3rem',
};

const SiteThemeProvider = ({ children }) => {
  return <ThemeProvider theme={theme}>{ children }</ThemeProvider>;
};



export default SiteThemeProvider