import React from "react";
import { Paper, Typography } from "@material-ui/core";

const Footer = () => {
  return (
    <Paper className="footer">
      <Typography>&copy; 2021 FehrIsFair</Typography>
    </Paper>
  );
};
export default Footer;
